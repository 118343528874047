import MinusIcon from '@components/icons/minus-icon'
import PlayArrowIcon from '@components/icons/playarrow'
import PlusIcon from '@components/icons/plus-icon'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { ChangeEvent, FC, MouseEvent } from 'react'
import { ImSpinner2 } from 'react-icons/im'
import { toast } from 'react-toastify'
import { ITEM_ADDTOCART_MAX_LIMIT } from '../../constants'

type ButtonEvent = (e: MouseEvent<HTMLButtonElement | MouseEvent>) => void
type InputChangeEvent = (e: ChangeEvent<HTMLInputElement>) => void

type CounterProps = {
  value: number
  variant?: 'mercury' | 'cart' | 'single' | 'venus' | 'card'
  onDecrement?: ButtonEvent
  onIncrement: ButtonEvent
  onInputChange?: InputChangeEvent
  addToCart?: ButtonEvent
  className?: string
  disabled?: boolean
  dataTestId?: string
  index?: number
  loading?: boolean
}

const Counter: FC<CounterProps> = ({
  value,
  variant = 'mercury',
  onDecrement,
  onIncrement,
  addToCart,
  onInputChange,
  className,
  disabled,
  dataTestId,
  index,
  loading,
}) => {
  const { t: translate } = useTranslation('common')

  const inputClickHandler = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation()
  }

  const handleInputChange: InputChangeEvent = (e) => {
    const newValue = Math.min(Number(e.target.value), 2000)
    if (onInputChange) {
      e.target.value = newValue.toString()
      onInputChange(e)
    }
  }

  const onIncrementHandler = (e) => {
    if (variant === 'card' && addToCart) {
      addToCart(e)
      return
    }
    if (value >= 2000) {
      toast.error(
        translate('text-max-qty-message') + ' ' + ITEM_ADDTOCART_MAX_LIMIT,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-error',
        },
      )
      return
    }
    if (onIncrement) {
      onIncrement(e)
    }
  }

  const size = variant === 'single' ? '22' : '14'
  return (
    <div
      // style={{
      //   boxShadow: '0px 20px 35px 0px rgba(0, 0, 0, 0.15)',
      // }}
      className={cn(
        'flex items-center justify-between rounded overflow-hidden shrink-0 p-1',
        {
          'h-9 md:h-10 bg-brand-light shadow-counter rounded-3xl':
            variant === 'mercury',
          'md:h-16 bg-[#f3f5f9]': variant === 'single',
          'inline-flex': variant === 'cart',
          'bg-brand rounded-[4px] z-1 mt-[10px]': variant === 'venus',
        },
        className,
      )}
    >
      <button
        onClick={onDecrement}
        data-testid={`${dataTestId}_product_button_decrease_quantity${
          index ? `(${index})` : ''
        }`}
        className={cn(
          'counter-btn-1 flex items-center justify-center shrink-0 h-full transition-all ease-in-out duration-300 focus:outline-none focus-visible:outline-none',
          {
            'w-10 h-8 rounded-2xl text-heading text-brand hover:bg-fill-four':
              variant === 'mercury',
            '!w-10 !h-10 text-brand rounded-full transform scale-80 lg:scale-100 hover:bg-fill-four ltr:ml-auto rtl:mr-auto':
              variant === 'single',
            '!w-5 sm:!w-6 !h-5 sm:!h-6 pr-0 border text-gray-400 border-solid border-border-three hover:bg-brand hover:border-brand rounded-full hover:text-brand-light':
              variant === 'cart',
            'w-10 h-10 cursor-pointer rounded-tl-[4px] rounded-bl-[4px] transition-all bg-black/10 text-white':
              variant === 'venus',
            // '!w-10 !h-10 bg-brand hover:bg-brand text-white rounded-tr rounded-br scale-80 lg:scale-100 text-heading ltr:mr-auto rtl:ml-auto !pr-0 justify-center':
            hidden: variant === 'card',
          },
        )}
      >
        <span className="sr-only">{translate('button-minus')}</span>
        <MinusIcon width={size} height={size} />
      </button>

      {onInputChange ? (
        <>
          <label
            htmlFor={`counter-input-${dataTestId}${index ? `-${index}` : ''}`}
            className="sr-only"
          >
            {translate('input-label')}
          </label>
          <input
            id={`counter-input-${dataTestId}${index ? `-${index}` : ''}`}
            className={cn(
              'counter-input p-1 border-0 font-semibold text-center text-brand-dark focus:ring-transparent focus:outline-none h-full text-sm w-12',
              {
                'rounded-tl rounded-bl pl-0 pr-0 !w-6 !h-7 border-2 focus:border-brand border-solid border-brand focus text-brand':
                  variant === 'card',
                'text-base text-brand md:text-[17px] w-12 md:w-20':
                  variant === 'single',
                'text-15px': variant === 'cart',
                'self-center text-sm sm:text-base text-white font-semibold':
                  variant === 'venus',
              },
            )}
            value={value}
            data-testid={`${dataTestId}_product_input_quantity${
              index ? `(${index})` : ''
            }`}
            type="number"
            max="2000"
            onClick={inputClickHandler}
            onChange={handleInputChange}
          />
        </>
      ) : (
        <span
          className={cn(
            'font-semibold text-brand-dark flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default shrink-0 text-',
            {
              'text-sm md:text-base w-6': variant === 'mercury',
              'text-base md:text-[17px] w-12 md:w-20 xl:w-28':
                variant === 'single',
              'text-15px w-9 text-lg': variant === 'cart',
              'self-center text-sm sm:text-base text-white font-semibold':
                variant === 'venus',
              'text-base md:text-[17px] w-12 md:w-21 xl:w-28':
                variant === 'card',
            },
          )}
          data-testid={`${dataTestId}_text_item_counter_qty(${index})`}
        >
          {value}
        </span>
      )}

      <button
        onClick={onIncrementHandler}
        disabled={loading}
        data-testid={`${dataTestId}_product_button_increase_quantity${
          index ? `(${index})` : ''
        }`}
        className={cn(
          'counter-btn-2 group flex items-center justify-center h-full shrink-0 transition-all ease-in-out duration-300 focus:outline-none focus-visible:outline-none pr-2',
          {
            'w-10 h-8 rounded-2xl text-heading text-brand hover:bg-fill-four !pr-0':
              variant === 'mercury',
            // '!w-6 !h-8 bg-brand hover:bg-brand rounded-tr rounded-br text-white scale-80 lg:scale-100 text-heading ltr:mr-auto rtl:ml-auto !pr-0 justify-center':
            '!w-6 !h-7 bg-brand hover:opacity-80  text-white rounded-tr rounded-br  lg:scale-100 text-heading ltr:mr-auto rtl:ml-auto !pr-0 justify-center':
              variant === 'card',
            '!w-10 !h-10 text-brand rounded-full scale-80 lg:scale-100 text-heading hover:bg-fill-four ltr:mr-auto rtl:ml-auto !pr-0 justify-center':
              variant === 'single',
            '!w-5 sm:!w-6 !h-5 sm:!h-6 border border-solid text-gray-400 border-border-three hover:bg-brand hover:border-brand rounded-full hover:text-brand-light !pr-0':
              variant === 'cart',
            'w-10 h-10 cursor-pointer rounded-tl-[4px] rounded-bl-[4px] transition-all bg-black/10 text-white !pr-0':
              variant === 'venus',
          },
        )}
        title={disabled ? 'Out Of Stock' : ''}
      >
        {loading ? (
          <ImSpinner2 className="w-3 h-3 animate-spin" />
        ) : (
          <>
            <span className="sr-only">{translate('button-plus')}</span>
            <PlusIcon width={size} height={size} />
          </>
        )}
      </button>

      {/* <div className="flex flex-col">
          <button
            onClick={onIncrement}
            // disabled={disabled}
            className="mb-0.5"
            data-testid={`${dataTestId}_button_item_counter_increase(${index})`}
            // title={disabled ? 'Out Of Stock' : ''}
          >
            <PlayArrowIcon />
          </button>
          <button
            onClick={onDecrement}
            // disabled={disabled}
            className="rotate-180"
            data-testid={`${dataTestId}_button_item_counter_decrease(${index})`}
            // title={disabled ? 'Out Of Stock' : ''}
          >
            <PlayArrowIcon />
          </button>
        </div> */}
    </div>
  )
}

export default Counter
