import { FC, useState } from 'react'
import { productPlaceholder } from '@assets/placeholders'
import { useModalAction } from '@components/common/modal/modal.context'
import { Eye } from '@components/icons/eye-icon'
import Image from '@components/ui/image'
import { useCart } from '@contexts/cart/cart.context'
import usePrice from '@framework/product/use-price'
import { Product } from '@framework/types'
import useWindowSize from '@utils/use-window-size'
import { convertProduct } from '@framework/utils/convert-product'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'

import { IoStarSharp } from 'react-icons/io5'
import StarRatingComponent from 'react-star-rating-component'
import { getCurrentPrice, getOldPrice, getShipInDays } from '@utils/helper'
import { useRouter } from 'next/router'
import Counter from '@components/ui/counter'
import { generateCartItem } from '@utils/generate-cart-item'
import Button from '@components/ui/button'
import Link from 'next/link'
import { CommonProductTitle } from '@utils/common-product-title'

const AddToCart = dynamic(() => import('@components/product/add-to-cart'), {
  ssr: false,
})
interface ProductProps {
  product: Product
  className?: string
  page?: string
  uniqueKey?: string
}

function RenderPopupOrAddToCart({ props }: { props: Object }) {
  let { data }: any = props
  const { t: translate } = useTranslation('common')
  const { id, quantity, product_type, is_active } = convertProduct(data) ?? {}
  const { width } = useWindowSize()
  const { openModal } = useModalAction()
  const { isInCart, isInStock } = useCart()
  const iconSize = width! > 1024 ? '19' : '17'
  const outOfStock = isInCart(id) && !isInStock(id)
  function handlePopupView() {
    openModal('PRODUCT_VIEW', data)
  }
  if (Number(quantity) < 1 || outOfStock) {
    return (
      <span className="text-[11px] md:text-xs font-bold text-brand-light uppercase inline-block bg-brand-danger rounded-full px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1">
        {translate('text-out-stock')}
      </span>
    )
  }
  if (!is_active) {
    return (
      <span className="text-[11px] md:text-xs font-bold text-brand-light uppercase inline-block bg-brand-danger rounded-full px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1">
        {translate('text-out-stock')}
      </span>
    )
  }
  if (product_type === 'variable') {
    return (
      <Button
        className="inline-flex items-center justify-center w-8 h-8 text-4xl rounded-full bg-brand lg:w-10 lg:h-10 text-brand-light focus:outline-none focus-visible:outline-none"
        aria-label="Count Button"
        onClick={handlePopupView}
      >
        <Eye width={iconSize} height={iconSize} opacity="1" />
      </Button>
    )
  }
  return <AddToCart data={data} variant="mercury" />
}

const ProductCard = ({
  product,
  gallery,
  name,
  brand,
  productUrl,
  page = 'home',
  uniqueKey = '',
}) => {
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [addToCartLoader, setAddToCartLoader] = useState<boolean>(false)
  const { t: translate } = useTranslation('common')
  const { addItemToCart } = useCart()
  const {
    case_quantity,
    stock_type,
    shipping_type,
    is_active,
    is_in_stock,
    flags,
    ships_in_days,
    price,
    special_price,
    special_from_date,
    special_to_date,
    mag_attributes,
    attributes,
  } = convertProduct(product) || {}

  const isInStock = is_in_stock || false
  const isNewArrival = flags?.includes('new_arrivals') || false
  const isRebateEligible = flags?.includes('rebate_eligible') || false
  const isFreeShipping = shipping_type === 'Ships Free'
  const isSpecialOrder = flags?.includes('special_order') || false

  const isBuyMoreSaveMore =
    mag_attributes?.find((item) => item.attribute_code === 'tier_prices')?.value
      ?.length > 0

  const shipsInDays = getShipInDays(ships_in_days, translate)

  const priceDetails = {
    special_price,
    special_from_date,
    special_to_date,
    price,
  }

  const currentPrice = getCurrentPrice(priceDetails)
  const oldPrice = getOldPrice(priceDetails)

  const { price: formattedCurrentPrice } = usePrice({
    amount: currentPrice,
    baseAmount: price,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const { price: formattedOldPrice } = usePrice({
    amount: oldPrice ?? 0, // If old price is null, pass 0 to the hook
    baseAmount: price,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numberFlag = isNaN(parseInt(event.target.value))
    let newValue = numberFlag ? 1 : parseInt(event.target.value)
    if (newValue < 1) return false
    setSelectedQuantity(newValue)
  }

  function addToCart() {
    setAddToCartLoader(true)
    setTimeout(() => {
      setAddToCartLoader(false)
    }, 1500)
    const item = generateCartItem(product!)
    const updatedItem = {
      ...item,
    }
    addItemToCart(updatedItem, selectedQuantity)
  }

  return (
    <div className="min-h-[inherit]">
      <div
        className={`prod-card min-h-[inherit] bg-white-- flex flex-col h-full relative-- overflow-visible rounded-lg`}
        data-testid={`${page}-element-${uniqueKey}-product-wrap`}
      >
        <div
          className="flex flex-col items-start tags absolute gap-1"
          data-testid={`${page}-tag-${uniqueKey}-parentElement`}
        >
          {isNewArrival && (
            <span
              className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-brand-blue border bg-fill-whiteOpacity rounded-[15px] border-solid border-brand-blue left-2.5 top-2.5`}
              data-testid={`${page}-tag-${uniqueKey}-new-arrivals`}
            >
              {/* New Arrivals */}
              {translate('text-new-arrivals')}
            </span>
          )}

          {isRebateEligible && (
            <span
              className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-orange border bg-fill-whiteOpacity rounded-[15px] border-solid border-orange left-2.5 top-10`}
              data-testid={`${page}-tag-${uniqueKey}-rebate-eligible`}
            >
              {/* Rebate - Eligible */}
              {translate('text-rebate-eligible')}
            </span>
          )}

          {isFreeShipping && (
            <span
              className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-brand-green border bg-fill-whiteOpacity rounded-[15px] border-solid border-green left-2.5 top-[70px]`}
              data-testid={`${page}-tag-${uniqueKey}-free-shipping`}
            >
              {/* Free Shipping */}
              {translate('text-free-shipping')}
            </span>
          )}
        </div>

        <div className="p-2.5 md:px-4 px-2.5">
          <div
            className="flex overflow-hidden w-auto max-w-[230px] h-auto max-h-[150px] mx-auto transition duration-200 ease-in-out transform group-hover:scale-105 relative mb-2"
            style={{ aspectRatio: '23 / 20' }}
          >
            <Image
              src={
                ((product && product['image.thumbnail']) ||
                  (gallery && gallery?.[0]?.thumbnail)) ??
                productPlaceholder
              }
              alt={name || 'Product Image'}
              fill={true}
              quality={100}
              loading="lazy"
              className="object-cover bg-fill-thumbnail"
              data-testid={`${page}-img-${uniqueKey}-product-image`}
            />

            {isInStock === false && (
              <div
                className={`flex flex-col items-center justify-center absolute text-white w-full h-16 text-xl font-medium leading-5 rounded-sm left-0 top-2/4 bg-black-100 font-sofia-pro`}
              >
                <h4 className="mb-0 text-center">
                  {translate('text-out-of-stock')}
                </h4>
              </div>
            )}
          </div>
          <h2
            title={CommonProductTitle(product)}
            className={`mb-0 md:mb-1 line-clamp-2 text-xl font-medium normal-case text-brand-dark max-h-[45px] md:max-h-[50px] leading-4 lg:leading-6`}
            data-testid={`${page}-text-${uniqueKey}-product-title-parent`}
          >
            <Link
              href={productUrl}
              onClick={(e: any) => e.stopPropagation()}
              className="hover:underline hover:font-semibold capitalize text-13px sm:text-sm lg:text-15px"
              data-testid={`${page}-link-${uniqueKey}-product-title`}
            >
              {' '}
              {brand && name && name.indexOf(String(brand)) === -1
                ? String(brand).toLocaleLowerCase()
                : ''}{' '}
              {String(name).toLocaleLowerCase()}
            </Link>
          </h2>

          <span
            className={`relative text-[11px] sm:text-[12px] whitespace-nowrap xsp:whitespace-normal font-medium leading-[21px] text-brand-gray font-poppins`}
            style={{
              top: '-3px',
            }}
            data-testid={`${page}-text-${uniqueKey}-product-per-case`}
          >
            {case_quantity} {translate('text-per-case')}
          </span>

          <div
            className="flex items-center -ml-0.5 mt-0 mb-2"
            data-testid={`${page}-img-${uniqueKey}-start-rating`}
          >
            <StarRatingComponent
              name="prouductcard"
              starCount={5}
              value={attributes?.product_review?.rating_avg}
              starColor="#FCA120"
              editing={false}
              emptyStarColor="#DFE6ED"
              renderStarIcon={() => (
                <IoStarSharp
                  fontSize={19}
                  className="-mr-1 !text-[14px] md:!text-[17px]"
                />
              )}
            />
          </div>

          {isSpecialOrder && (
            <div className={`mb-1 md:mb-3--`}>
              <div className="inline-flex relative">
                <Image
                  priority
                  src="/assets/images/special-order-tag.svg"
                  width={75}
                  height={16}
                  alt="Special Order"
                  className="w-[75px] md:w-[91px]"
                  data-testid={`${page}-img-${uniqueKey}-special-order`}
                />
              </div>
            </div>
          )}

          {/* )} */}
          <p className="text-[10px] md:text-[12px] font-[500] !mb-[2.85rem] leading-[18px]">
            {translate('text-ready-to-ship')}{' '}
            <span
              className={`${
                isSpecialOrder ? 'text-yellow' : 'text-brand-green'
              }`}
              data-testid={`${page}-text-${uniqueKey}-ready-to-ship-value`}
            >
              {shipsInDays?.value} {shipsInDays?.unit}(s)
            </span>
          </p>

          <div
            className="w-full-- md:pl-[4px] absolute bottom-[8px] left-[10px]"
            data-testid={`${page}-text-${uniqueKey}-price-parent`}
          >
            {oldPrice && (
              <div className="-mt-2-- mb-1-- max-400:mb-1">
                <del
                  className="text-[14px] max-400:text-[11px] font-medium text-brand-gray"
                  data-testid={`${page}-text-${uniqueKey}-old-price`}
                >
                  {formattedOldPrice.replace('CA', '')}
                </del>
              </div>
            )}

            <div className="max-400:leading-[14px]  max-400:flex-col max-400:items-start max-400:-mb-[4px] flex  flex-wrap-- flex-col-- xsp:flex-row-- items-baseline -mt-1">
              <span
                className={`max-400:text-[14px] text-[17px] sm:text-[20px] font-medium tracking-[-0.01em] text-grey-960 font-poppins`}
                data-testid={`${page}-text-${uniqueKey}-current-price`}
              >
                {formattedCurrentPrice.replace('CA', '')}
              </span>

              <span
                className={`ml-1 italic relative max-400:text-[8px] text-[10px] sm:text-[12px] whitespace-nowrap xsp:whitespace-normal font-medium leading-[21px] text-grey-960 font-poppins`}
                style={{
                  top: '-3px',
                }}
                data-testid={`${page}-text-${uniqueKey}-case`}
              >
                / {translate('text-per-case')}
              </span>
            </div>

            {/* {isBuyMoreSaveMore && (
              <div className="flex mb-1">
                <span
                  className={`text-brand-darkblue rounded text-xs font-medium inline-flex items-center border px-[3px] py-1 border-dashed border-grey-900`}
                >
                  <Image
                    className=""
                    style={{ marginRight: '2px' }}
                    priority
                    src="/assets/images/buy-more-dollar-tag.svg"
                    width={12}
                    height={12}
                    alt="Buy More Dollar Tag"
                  />
                  <span
                    className={`relative text-[8px] font-medium leading-3 text-grey-960`}
                    style={{ top: '1px' }}
                  >
                    Buy More, Save More
                  </span>
                </span>
              </div>
            )} */}
          </div>
        </div>

        <div className="absolute bottom-0 lg:bottom-0 right-0 mt-auto pt-0 flex justify-between items-center">
          <div
            className={`block-- product-count-button-position-- relative w-[181px] flex justify-end items-center`}
            style={{
              right: '10px',
              bottom: '10px',
            }}
            onClick={(e) => e.stopPropagation()} // Prevent event bubbling
            data-testid={`${page}-btn-${uniqueKey}-counter-parent`}
          >
            <Counter
              loading={addToCartLoader}
              variant="card"
              value={selectedQuantity}
              addToCart={addToCart}
              onDecrement={() =>
                setSelectedQuantity((prev) => (prev !== 1 ? prev - 1 : 1))
              }
              onInputChange={inputChangeHandler}
              dataTestId={`${page}-btn-${uniqueKey}-counter`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ProductCardNew: FC<ProductProps> = ({
  product,
  className,
  page = 'home',
  uniqueKey = '',
}) => {
  const router = useRouter()
  const { locale } = router

  const { name, image, product_type, brand, case_quantity, gallery, slug } =
    convertProduct(product, locale) ?? {}
  const { openModal } = useModalAction()

  const { price, basePrice, discount } = usePrice({
    amount: product?.sale_price ? product?.sale_price : product?.price,
    baseAmount: product?.price,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })
  const { price: minPrice } = usePrice({
    amount: product?.min_price ?? 0,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })
  const { price: maxPrice } = usePrice({
    amount: product?.max_price ?? 0,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })
  function handlePopupView() {
    openModal('PRODUCT_VIEW', product)
  }
  const productUrl = `/${slug}`
  return (
    <article
      className={`${cn(
        'block group rounded-md cursor-pointer transition-all duration-300 h-full overflow-visible',
        className,
      )}`}
      onClick={handlePopupView}
      title={name}
    >
      <ProductCard
        product={product}
        gallery={gallery}
        name={name}
        brand={brand}
        productUrl={productUrl}
        product_type={product_type}
        basePrice={basePrice}
        minPrice={minPrice}
        maxPrice={maxPrice}
        page={page}
        uniqueKey={uniqueKey}
      />
    </article>
  )
}
export default ProductCardNew
